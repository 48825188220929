import React from "react"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"
import {withPrefix} from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Gallery from "../../components/Gallery"
import Content from "../../components/Content"

const galImgs = [
  "/page-images/clarke_house_rear_elevation_image_small_file_oct_2013.jpg"
]


export default function AboutUs() {
  return (
    <Layout breadcrumbs={["About Us"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )} 
    />

      <Content>
    <p><strong>Practice Profile</strong></p>
<p>	Crockard Building Design is very much a client focused practice.  Whilst we bring our design experience and technical knowledge to every commission, our clients needs and desires remain our focus.  We do however make it our mission to introduce new ideas, develop design solutions and suggest materials that our clients may not necessarily have considered.  It is ultimately the fusion of these elements, and of personalities, that create the individual solutions of which all involved can be proud. &nbsp;</p>
<p>	The diverse nature of projects undertaken by our staff both in this practice and through previous positions within the profession has provided us a wealth of design experience and practical knowledge equipping us to deliver consistently high quality work over a wide range of project types. Completed works range from small alterations to multi-million pounds projects, which include domestic, residential, commercial, community, leisure and industrial buildings.</p>
      <p>	The practice occupies purpose-built studios, which provide both a creative working environment and a great space to meet clients when discussing and reviewing projects.</p>
      </Content>


      <div class="region region-content-bottom">
        <MenuOne active="about-us"></MenuOne>
        <MenuTwo
          active="profile"
          items={['../profile', '../design-approach', '../value-and-commitment', '../the-team']}>
        </MenuTwo>
      </div>

  </Layout>

  )
}
